import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';

import styles from './Footer.module.scss';

const Footer = ({
    footerMainMenu = [],
    footerPrivacyMenu = {
        items: [],
        copyrightYear: 2020
    },
    socialMediaProfiles = [],
}) => {
    return (
        <footer className={styles['Footer']}>
            <div className={styles['Footer__Container']}>
                <MainNav items={footerMainMenu} />
                <SocialNav profiles={socialMediaProfiles} />
                <PrivacyNav {...footerPrivacyMenu} />
                <SwedenLogotypes />
            </div>
        </footer>
    );
};

Footer.propTypes = {
    footerMainMenu: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ),
    footerPrivacyMenu: PropTypes.object,
    socialMediaProfiles: PropTypes.array,
};

const SocialNav = ({ profiles = [] }) => {
    console.log(profiles);
    const { t } = useTranslation('common');
    return (
        <nav
            role="navigation"
            aria-labelledby="socialFooterNav"
            className={styles['Footer__SocialNav']}>
            <h2
                id="socialFooterNav"
                className={styles['Footer__SocialNav__Heading']}>
                {t('footer.followus')}
            </h2>
            <div className={styles['Footer__SocialNav__Container']}>
                {profiles?.map((profile, i) => (
                    <a
                        key={i}
                        href={profile.url}
                        className={`${styles['Footer__SocialNav__Link']} ${styles[`Footer__SocialNav__Link--${profile.icon}`]}`}>
                        <span className='sr-only'>{t(`footer.${profile.icon}`)}</span>
                    </a>
                ))}
            </div>
        </nav>
    );
};

SocialNav.propTypes = {
    profiles: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
        })
    ).isRequired,
};

const MainNav = ({ items }) => {
    const { t } = useTranslation('common');
    return (
        <nav
            role="navigation"
            aria-labelledby="mainFooterNav"
            className={styles['Footer__MainNav']}>
            <h2 className="sr-only" id="mainFooterNav">
                {t('footer.mainNavHeader')}
            </h2>
            <ul className={styles['Footer__MainNav__Container']}>
                {items.map((item, i) => (
                    <li key={i} className={styles['Footer__MainNav__Item']}>
                        <a
                            className={styles['Footer__MainNav__Link']}
                            href={item.href}>
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

MainNav.propTypes = {
    items: PropTypes.array.isRequired,
};

const SwedenLogotypes = () => {
    return (
        <div className={styles['Footer__SwedenLogotypes']}>
            <a
                className={styles['Footer__SwedenLogotypes__Link']}
                href="https://sweden.se">
                <div
                    className={
                        styles['Footer__SwedenLogotypes__Logo'] +
                        ' ' +
                        styles['Footer__SwedenLogotypes__Logo--Sweden']
                    }>
                    <span className="sr-only">Sweden.se</span>
                </div>
            </a>
            <div
                className={
                    styles['Footer__SwedenLogotypes__Link'] +
                    ' ' +
                    styles['Footer__SwedenLogotypes__Link--Second']
                }>
                <div
                    className={
                        styles['Footer__SwedenLogotypes__Logo'] +
                        ' ' +
                        styles['Footer__SwedenLogotypes__Logo--VisitSweden']
                    }>
                    <span className="sr-only">VisitSweden.com</span>
                </div>
            </div>
        </div>
    );
};

const PrivacyNav = ({ items = [], copyrightYear }) => {
    const { t } = useTranslation('common');
    return (
        <nav
            role="navigation"
            aria-labelledby="privacyFooterNav"
            className={styles['Footer__PrivacyNav']}>
            <h2 id="privacyFooterNav" className="sr-only">
                {t('footer.privacyNavHeader')}
            </h2>
            <ul className={styles['Footer__PrivacyNav__Container']}>
                {items.map((item, i) => (
                    <li key={i} className={styles['Footer__PrivacyNav__Item']}>
                        <a
                            className={styles['Footer__PrivacyNav__Link']}
                            href={item.href}>
                            {item.title}
                        </a>
                    </li>
                ))}
            </ul>
            <div className={styles['Footer__PrivacyNav__CopyrightNotice']}>
                {t('footer.copyright', { copyrightYear })}
            </div>
        </nav>
    );
};

PrivacyNav.propTypes = {
    items: PropTypes.array.isRequired,
    copyrightYear: PropTypes.number.isRequired,
};

export default Footer;
